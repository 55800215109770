import { useState } from "react";
import { ModalBranchData } from "./components/ModalBranchData/ModalBranchData";

export const useResellers = (props) => {

  const googleMapsSiteKey = String(process.env.REACT_APP_GOOGLE_MAPS_SITE_KEY);
  const defaultBranchData = props.data ? props.data.branches : [];

  const [showModal, changeShowModal] = useState(false);
  const [activeBranchName, changeActiveBranchName] = useState("No name");
  const [activeBranchData, changeActiveBranchData] = useState(<></>);

  // Shows branch data in modal
  // when user clicks on the map
  const handleClick = (branch) => {

    const phone = (branch.phone ? branch.phone : props.data.branch_default_phone);
    const email = (branch.email ? branch.email : props.data.branch_default_email);

    changeActiveBranchName(branch.name);
    changeActiveBranchData(<ModalBranchData phone={phone} email={email} branch={branch}/>);

    changeShowModal(true);
  }

  return {
    googleMapsSiteKey,
    showModal,
    changeShowModal,
    handleClick,
    defaultBranchData,
    activeBranchName, 
    activeBranchData
  };
};

export default useResellers;
