import React from "react";
import "./ModalBranchData.css";

export const ModalBranchData = (props) => {

    return (
        <table id="modal-branch-data">
        <tbody>
          <tr>
            <td><i className={"fa fa-phone icon-font-size"} aria-hidden="true" /></td><td>Teléfono:</td><td>{props.phone}</td>
          </tr>
          <tr>
            <td><i className={"fa fa-envelope icon-font-size"} aria-hidden="true" /></td><td>Email:</td><td>{props.email}</td>
          </tr>
          <tr>
            <td><i className={"fa fa-building icon-font-size"} aria-hidden="true" /></td><td>Dirección:</td><td>{props.branch.address}</td>
          </tr>
          <tr>
            <td><i className={"fa fa-map-marker icon-font-size"} aria-hidden="true" /></td><td>Localidad:</td><td>{props.branch.city_name + ",\n" + props.branch.province_name}</td>
          </tr>
          <tr>
            <td><i className={"fa fa-globe icon-font-size"} aria-hidden="true" /></td><td>País:</td><td>{props.branch.country_name}</td>
          </tr>
        </tbody>
      </table>
    );
};
