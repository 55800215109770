import React from "react";
import CustomModal from "../../../../components/CustomModal/CustomModal";
import { APIProvider, Map, AdvancedMarker } from '@vis.gl/react-google-maps';
import { useResellers } from "./useResellers.js";
import { useResellersApi } from "./useResellersApi.js";
import markerIcon from "./assets/marker_icon.webp";
import "./Resellers.css";

export const Resellers = (props) => {

  let {
    googleMapsSiteKey,
    showModal,
    changeShowModal,
    handleClick,
    defaultBranchData,
    activeBranchName, 
    activeBranchData,
  } = useResellers(props);

  let {
    mapCenter,
    changeMapCenter,
    mapZoomLevel,
    changeMapZoomLevel,
    branchData
  } = useResellersApi(defaultBranchData);

  return (
    <div id="resellers" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>
            {props.data ? props.data.title : "Loading"}
          </h2>
          <p>
            {props.data ? props.data.description : "Loading"}
          </p>
        </div>
      </div>
      { /* Important! Always set the container height explicitly */ }
      <div id="resellers-map-container" className="map-container" style={{ height: "60vh" }}>
        { !branchData ? (
          <div id="resellers-map-container-overlay" className="map-container-overlay">
            <span>
              <i className="fa fa-gear fa-spin icon-font-size" aria-hidden="true"></i>
              <div className="caption">
                <p>{props.data ? props.data.loadingText : "Loading"}</p>
              </div>
            </span>
          </div>
        ) : (
          <></>
        )}
        <APIProvider apiKey={googleMapsSiteKey}>
          <Map
            mapId='ALIENTECH_AR_RESELLERS'
            // Sets center and zoom levels from the API
            // If not defined, defaults below take precedence
            center={mapCenter}
            zoom={mapZoomLevel}
            defaultCenter={props.data ? props.data.map_default_center : {lat: -33.00473540525955, lng: -61.8155367791016}}
            defaultZoom={props.data ? props.data.map_default_zoom : 5}
            fullscreenControl={false}
            streetViewControl={false}
            mapTypeControl={false}
            // Disable fixed center and zoom level so that the
            // user can control the map
            onDrag={() => changeMapCenter()}
            onZoomChanged={() => changeMapZoomLevel()}
          >
          {
            branchData?.map((branch) => (
              <AdvancedMarker
                key={branch.id}
                position={branch.position}
                clickable={true}
                onClick={() => handleClick(branch)}
                >
                <img
                  src={markerIcon}
                  className="map-custom-marker"
                  alt={branch.name}
                />
              </AdvancedMarker>
            ))              
          }
          </Map>
        </APIProvider>
      </div>
      <CustomModal
        show={showModal}
        onHide={() => changeShowModal(false)}
        title={activeBranchName}
        body={activeBranchData}
      />
    </div>
  );
};
