import React from "react";
import { useAcademy } from "./useAcademy.js";
import Course from "./components/Course/Course";
import "./Academy.css";

export const Academy = (props) => {

  let {
    records,
    recordsEmpty
  } = useAcademy();

  return (
    <div id="academy" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>
            {props.data ? props.data.title : "Loading"}
          </h2>
          <h3>{props.data ? props.data.subTitle : "Loading"}</h3>
          <p>
            {props.data ? props.data.description : "Loading"}
            <br />
            <br />
            {props.data ? props.data.description2 : "Loading"}
          </p>
        </div>
        <div className="row">
          {records ? (
              (Object.values(records) || []).map(record => {
                return (
                  <div key={record.id} className="academy-item">
                    <Course
                      id = {record.id}
                      name = {record.name}
                      images = {record.images}
                      attributes = {record.attributes}
                      permalink = {record.permalink}
                      noDateText = {props.data ? props.data.noDateText : "Loading"}
                      buttonText = {props.data ? props.data.buttonText : "Loading"}
                    />
                  </div>
                )}
              )) : (
                recordsEmpty ? (
                  <div id="norecords" className="academy-item">
                    <div className="thumbnail">
                      <i className="fa fa-exclamation-triangle icon-font-size academy-img" aria-hidden="true"></i>
                      <div className="caption">
                        <p>{props.data ? props.data.noResultsText : "Loading"}</p>
                      </div>
                    </div>
                  </div>
                )
                : (
                  <div id="loading" className="academy-item">
                    <div className="thumbnail">
                      <i className="fa fa-gear fa-spin icon-font-size academy-img" aria-hidden="true"></i>
                      <div className="caption">
                        <p>{props.data ? props.data.loadingText : "Loading"}</p>
                      </div>
                    </div>
                  </div>
              )
            )            
          }
        </div>
      </div>
    </div>
  );
};
