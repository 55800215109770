import { useState, useEffect } from "react";
import { apiShop } from "../../../../api/api.js";

export const useAcademy = () => {

    const [records, changeRecords] = useState()
    const [recordsEmpty, changeEmptyRecords] = useState()


    // Removes past courses from results
    const filterCourses = (course) => {
  
        let today = Date.now();
        return (course.attributes.date_start > today);
    };


    // Sorts courses by ascending date
    const compareCourses = (course1, course2) => {
  
      if (course1.attributes.date_start > course2.attributes.date_start) {
        return 1;
      }
      if (course1.attributes.date_start < course2.attributes.date_start) {
        return -1;
      }
      return 0;
    };


    // Parses course short description for start date
    // Returns "Invalid date" if not found or invalid
    const parseCourseDate = (course) => {
 
      let result, dateSplitted, day, month, year;

      result = course.short_description.match("Inicio: [0-9]{4}([-/ .])[0-9]{2}[-/ .][0-9]{2}");
      if(null != result) {
          dateSplitted = result[0].split(result[1]);
          day = dateSplitted[2];
          month = dateSplitted[1];
          year = dateSplitted[0];
      }
      
      return new Date(year + "-" + month + "-" + day + " 00:00:00.000");
    };


    // Fetches courses from WooCommerce API and
    // states update
    useEffect(() => {
      const academyCategory = String(process.env.REACT_APP_API_SHOP_CATEGORY_ID);
      // Category cheatsheet:
      // 119 -> powergate
      // 120 -> ecm-titanium
      // 121 -> kess-3
      // 123 -> academy
      const loadCourses = () => {
  
        changeEmptyRecords(false);
        changeRecords(null);  
  
        apiShop
          .get("/products?category=" + academyCategory + "&status=publish")
          .then((response) => {
            const returnedData = response;
            const resultsLength = returnedData.data.length;

            let r = [];
            let rValidDates = [];
            let rInvalidDates = [];
            if ( resultsLength > 0 ) {
              returnedData.data.forEach(record => {

                // Adds course start date to API resutls
                record.attributes.date_start = new Date(parseCourseDate(record));
                if (!isNaN(record.attributes.date_start)) {
                  rValidDates[record.id] = record;  
                }
                else {
                  rInvalidDates[record.id] = record;  
                }
              });
              rValidDates = rValidDates.filter(filterCourses);
              rValidDates = rValidDates.sort(compareCourses);

              r = rValidDates.concat(rInvalidDates);
              if (r.length > 0) {
                changeRecords(r);
                changeEmptyRecords(false);
              }
              else {
                changeRecords(null);
                changeEmptyRecords(true);
              }
            } else {
              changeRecords(null);
              changeEmptyRecords(true);
            }
          })
          .catch((error) => {
            changeRecords(null);
            changeEmptyRecords(true);
            console.error(error);
          });
      }
      loadCourses();

      return () => {
        changeRecords();
        changeEmptyRecords();
      }

    }, [changeRecords, changeEmptyRecords]);

    return {
      records,
      recordsEmpty
    };
};

export default useAcademy;
