import React from "react";
import defaultImage from "./assets/academy_default.webp";
import "./Course.css";


export const Course = (props) => {

  return (
    <>
        <div className="thumbnail">
        {( props.images.length > 0 ?
            <img src={props.images[0].src} alt="..." className="academy-img" />
            :
            <img src={defaultImage} alt="..." className="academy-img" />
        )}
        <div className="caption">
            <p>
            <i className="fa fa-calendar academy-sep" aria-hidden="true" />
            {( props.attributes.date_start && props.attributes.date_start != null && !isNaN(props.attributes.date_start) ?
                <span>Inicio: {props.attributes.date_start.toISOString("YYYY-MM-DD").substring(0, 10)}</span>
                :
                <span>{props.noDateText}</span>
            )}
            </p>
            <h4>{props.name}</h4>
        </div>
        <a
            href={props.permalink}
            target="_blank"
            rel="noreferrer"
            className="btn btn-custom btn-lg page-scroll"
        >                      
            {props.buttonText}
        </a>
        </div>
    </>
  );
};

export default Course;
