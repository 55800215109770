import React from "react";
import siteLogo from "./assets/alientech_logotype_dark_background.webp"
import { useWindowSize } from "../../utils/useWindowSize.js"
import "./Navigation.css";


export const Navigation = (props) => {

  // Used for pages where there is no scrolling involved (as in the Home page)
  const explicitUrls = props.explicitUrls === true ? true : false;

  // Used to manually toggle bootstrap's "data-toogle" and data-target"
  // and avoid flickering
  // Match the breakpoint with the media query in "Navigation.css"
  const windowSize = useWindowSize();
  const navBreakpoint = 1200;
  const dataToggle = windowSize.width < navBreakpoint ? "collapse" : null;
  const dataTarget = windowSize.width < navBreakpoint ? "#bs-navbar-collapse" : null;

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-navbar-collapse"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a
            href="/">
            <img
              id="company-logo"
              src={siteLogo}
              alt="Alientech Argentina"
            />
          </a>{" "}
        </div>
        <div
          className={"collapse navbar-collapse"}
          id="bs-navbar-collapse"
        >
          <ul className="nav navbar-nav navbar-right">
            <li
              data-toggle={ dataToggle }
              data-target={ dataTarget }
            >
              <a href={explicitUrls ? "/#products-services" : "#products-services"}
                 className="page-scroll"
              >
                Productos y Servicios
              </a>
            </li>
            <li
              data-toggle={ dataToggle }
              data-target={ dataTarget }
            >
              <a href={explicitUrls ? "/#academy" : "#academy"}
                 className="page-scroll"
              >
                Academy
              </a>
            </li>
            <li
              data-toggle={ dataToggle }
              data-target={ dataTarget }
            >
              <a href={props.data ? props.data.shopUrl : "Loading"}
                 target="_blank"
                 rel="noreferrer"
                 className="page-scroll"
              >
                Shop
              </a>
            </li>
            <li
              data-toggle={ dataToggle }
              data-target={ dataTarget }
            >
              <a href={props.data ? props.data.supportUrl : "Loading"}
                 target="_blank" 
                 rel="noreferrer"
                 className="page-scroll"
              >
                Soporte
              </a>
            </li>
            <li
              data-toggle={ dataToggle }
              data-target={ dataTarget }
            >
              <a href={explicitUrls ? "/#resellers" : "#resellers"}
                 className="page-scroll"
              >
                Resellers
              </a>
            </li>
            <li
              data-toggle={ dataToggle }
              data-target={ dataTarget }
            >
              <a href={explicitUrls ? "/#contact" : "#contact"}
                 className="page-scroll"
              >
                Contacto
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
