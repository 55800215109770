import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Toaster } from "react-hot-toast";
import { useContact } from "./useContact.js";
import { useReCaptcha } from "./useReCaptcha.js";
import "./Contact.css";

export const Contact = (props) => {

  let {
    captchaSiteKey,
    captchaCode,
    handleChangeCaptcha
  } = useReCaptcha();

  let {
    handleSubmit,
    handleChange,
    newForm,
    formSending,
    formSent,    
    formValidName,
    formValidEmail,
    formValidMessage,
    formError,
    messageSize,
    messageLimit
  } = useContact(props, captchaCode);

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Contáctenos</h2>
                <p>
                  Por favor llene el formulario para enviarnos un email y
                  nos contactaremos con usted lo más pronto posible.
                </p>
              </div>
              { !formSent ? (
              <form name="contact_form" noValidate onSubmit={handleSubmit}>
                <fieldset disabled="">
                  <div className="row">
                    <div className="col-md-6">
                      <div className={(newForm ? "" : (formValidName ? "has-success" : "has-error")) + " form-group"}>
                        <div className="input-group">
                          <span className="input-group-addon">
                            <i className="glyphicon glyphicon-user" />
                            </span>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            className="form-control"
                            placeholder="Nombre"
                            maxLength = "64"
                            required
                            onChange={handleChange}
                          />
                          <span className={"glyphicon " + (formValidName ? "glyphicon-ok" : "glyphicon-remove") + " form-control-feedback"} aria-hidden="true"></span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className={(newForm ? "" : (formValidEmail ? "has-success" : "has-error")) + " form-group"}>
                        <div className="input-group">
                          <span className="input-group-addon">
                            <i className="glyphicon glyphicon-envelope" />
                          </span>
                          <input
                            type="email"
                            id="email"
                            name="email"
                            className="form-control"
                            placeholder="Email"
                            maxLength = "64"
                            pattern="^[^@\s]+@[^@\s]+\.[^@\s]+$"
                            required
                            onChange={handleChange}
                          />
                          <span className={"glyphicon " + (formValidEmail ? "glyphicon-ok" : "glyphicon-remove") + " form-control-feedback"} aria-hidden="true"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={(newForm ? "" : (formValidMessage ? "has-success" : "has-error")) + " form-group"}>
                    <div className="input-group">
                      <span className="input-group-addon">
                        <i className="glyphicon glyphicon-pencil" />
                      </span>
                      <textarea
                        name="message"
                        id="message"
                        className="form-control"
                        rows="4"
                        placeholder="Mensaje"
                        maxLength = {messageLimit}
                        required
                        onChange={handleChange}
                      />
                      <span className={"glyphicon " + (formValidMessage ? "glyphicon-ok" : "glyphicon-remove") + " form-control-feedback"} aria-hidden="true"></span>
                    </div>
                    <span className="help-block">Máximo 256 caracteres ({messageSize} restantes).</span>
                  </div>
                  <ReCAPTCHA
                    sitekey={captchaSiteKey}
                    onChange={handleChangeCaptcha}
                  />
                  <button type="submit" className="btn btn-custom btn-lg">
                    Enviar Mensaje
                  </button>
                </fieldset>
              </form>
              ) : (
                  formSending ? (
                    <div id="sending">
                      <div className="icon-container">
                        <i className="fa fa-gear fa-spin icon-font-size" aria-hidden="true"></i>
                        <div className="caption">
                          <p>{props.data ? props.data.sendingText : "Loading"}</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    !formError ? (
                      <div id="sending">
                      <div className="icon-container">
                        <i className="fa fa-check-circle icon-font-size" aria-hidden="true"></i>
                        <div className="caption">
                          <p>{props.data ? props.data.sentText : "Loading"}</p>
                        </div>
                      </div>
                    </div>
                    ) : (
                      <div id="error">
                      <div className="icon-container">
                        <i className="fa fa-exclamation-triangle icon-font-size" aria-hidden="true"></i>
                        <div className="caption">
                          <p>{props.data ? props.data.errorText : "Loading"}</p>
                        </div>
                      </div>
                    </div>
                    )
                  )
                )
              }
            </div>
          </div>
          <div id="contact-info" className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contacto</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Dirección
                </span>
                {props.data ? props.data.addressLine1 : "loading"}<br />
                {props.data ? props.data.addressLine2 : "loading"}<br />
                {props.data ? props.data.addressLine3 : "loading"}<br />
                {props.data ? props.data.addressLine4 : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Teléfono
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-whatsapp"></i> Chat (soporte y ventas)
                </span>{" "}
                <a
                  href={props.data ? props.data.whatsappUrl : "loading"}
                  target="_blank"
                  rel="noreferrer"
                >                  
                  {props.data ? props.data.whatsapp : "loading"}
                </a>
              </p>
            </div>           
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
    </div>
  );
};
