import { useState } from "react";

export const useReCaptcha = () => {

    const captchaSiteKey = String(process.env.REACT_APP_RECAPTCHA_SITE_KEY);
    const [captchaCode, changeCaptchaCode] = useState("");
  
  
    const handleChangeCaptcha = (value) => {
  
      changeCaptchaCode(value);
    };
  
    return {
        captchaSiteKey,
        captchaCode,
        handleChangeCaptcha
    };
};

export default useReCaptcha;
