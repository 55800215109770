import { useState } from "react";
import { toast } from "react-hot-toast";
import { containsHtml } from "../../../../utils/containsHtml.js"
import { apiContact } from "../../../../api/api.js";

export const useContact = (props, captchaCode) => {

    const initialState = {
      name: "",
      email: "",
      message: "",
    };

    const messageLimit = 256;
    const [{ name, email, message }, setState] = useState(initialState);
  
    const [newForm, changeNewForm] = useState(true);
    const [formValidName, changeFormValidName] = useState(false);
    const [formValidEmail, changeFormValidEmail] = useState(false);
    const [formValidMessage, changeFormValidMessage] = useState(false);
    const [messageSize, changeMessageSize] = useState(messageLimit);
    const [formSending, changeFormSending] = useState(false);
    const [formSent, changeFormSent] = useState(false);
    const [formError, changeFormError] = useState(false);
  

    const handleChange = (e) => {
   
      const field = e.currentTarget;
      const { name, value } = field;
  
      switch(field.name) {
        case 'name':
          changeFormValidName(field.checkValidity () && field.value.trim() !== "" && !containsHtml(field.value));
          break;
        case 'email':
          changeFormValidEmail(field.checkValidity());
          break;
        case 'message':
          changeFormValidMessage(field.checkValidity() && field.value.trim() !== "" && !containsHtml(field.value));
          changeMessageSize(messageLimit - field.value.length);
          break; 
        default:
          break;
      }
  
      setState((prevState) => ({ ...prevState, [name]: value }));
      changeNewForm(false);
    };
    
  
    const validateForm = (form) => {
  
      let validName = form.name.checkValidity() && form.name.value.trim() !== "" && !containsHtml(form.name.value);
      let validEmail = form.email.checkValidity();
      let validMessage = form.message.checkValidity() && form.message.value.trim() !== "" && !containsHtml(form.message.value);
      let invalidCaptcha = captchaCode === null || captchaCode.length === 0;
  
      if (form.checkValidity() === false ||
          !validName ||
          !validEmail ||
          !validMessage ||
          invalidCaptcha) {
  
        changeFormValidName(validName);
        changeFormValidEmail(validEmail);
        changeFormValidMessage(validMessage);
        let validationErrorMessage = "";
        if (!validName) {
          validationErrorMessage+="\u{2022} Nombre: " + (containsHtml(form.name.value) ? "no se permite código" : "debe completar este campo") + ".\n";
        }
        if (!validEmail) {
          validationErrorMessage+="\u{2022} Email: " + (form.email.validity.valueMissing ? "debe completar este campo" : "no es un email válido") + ".\n";
        }
        if (!validMessage) {
          validationErrorMessage+="\u{2022} Mensaje: " + (containsHtml(form.message.value) ? "no se permite código" : "debe completar este campo") + ".\n";
        }
        if (invalidCaptcha) {
          validationErrorMessage+="\u{2022} Captcha: debe completar el challenge.\n";
        }
        toast.error(validationErrorMessage);
  
        return false;
      }
  
      return true;
    }
  
  
    const handleSubmit = (e) => {
  
      e.preventDefault();
      e.stopPropagation();
  
      changeNewForm(false);
      if (!validateForm(e.currentTarget)) {
  
        return;
      }
  
      let FormData = require("form-data");
      let clientContactData = new FormData();
      clientContactData.append("client-name", name);
      clientContactData.append("client-email", email);
      clientContactData.append("client-msg", message);
      clientContactData.append("g-recaptcha-response", captchaCode);
    
      //Log clientContactData
      //for(let pair of clientContactData.entries()) {
      //  console.log("\"" + pair[0] + "\", \"" + pair[1] + "\"");
      //}
  
      changeFormSending(true);
      changeFormSent(true);
      changeFormError(false);
  
      apiContact
      .post("/contact_alientech_ar", clientContactData)
      .then((response) => {
        if (response.data && !response.data.ok) {
  
          changeFormSending(false);
          changeFormSent(true);
          changeFormError(true);
          console.error(response.data.message);
          toast.error(response.data.message);
        }
        else {
  
          changeFormSending(false);
          changeFormSent(true);
          changeFormError(false); 
          toast.success(props.data.SuccessText);
        }
      })
      .catch((error) => {
  
        changeFormSending(false);
        changeFormSent(true);
        changeFormError(true);
        console.error(error);
        toast.error(props.data.unknownErrorText);
      });
    };

    return {
        handleSubmit,
        handleChange,
        newForm,
        formSending,
        formSent,    
        formValidName,
        formValidEmail,
        formValidMessage,
        formError,
        messageSize,
        messageLimit
    };
};

export default useContact;
