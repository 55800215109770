import React from "react";
import "./Product.css";


export const Product = (props) => {

  return (
    <>
      {" "}
      <a href={props.url} target="_blank" rel="noreferrer">
        <img className="ps-items-image" src={props.image} alt={props.title} />
      </a>
      <h3>{props.title}</h3>
      <p>{props.text}</p>
    </>
  );
};

export default Product;
