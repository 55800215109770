import { useState, useEffect } from "react";
import { apiResellers } from "../../../../api/api.js";

export const useResellersApi = (defaultBranchData) => {

  const [mapCenter, changeMapCenter] = useState();
  const [mapZoomLevel, changeMapZoomLevel] = useState();
  const [branchData, changeBranchData] = useState();

  // Fetches resellers from API and
  // states update
  useEffect(() => {

    const loadResellers = () => {

      changeBranchData(null);

      apiResellers
        .get("/get_alientech_ar_branches")
        .then((response) => {
          const returnedData = response;
          const returnedDataOk = returnedData.data.ok;
          const results = returnedData.data.data.branches.filter(branch => branch.show_in_map === true);
          const resultsLength = results.length;

          if ((returnedDataOk) && (resultsLength > 0)) {

            const mapCenterData = returnedData.data.data.map_default_center;
            const mapZoomLevelData = returnedData.data.data.map_default_zoom;

            // Set center, zoom and data retrieved from API
            // Loads branches from data.json            
            changeMapCenter(mapCenterData);
            changeMapZoomLevel(mapZoomLevelData);
            changeBranchData(results);
          }
          else {
            // No need to reset center and zoom values, will use defaults
            // Loads branches from data.json
            changeBranchData(defaultBranchData);
            console.error("Error fetching resellers: " + returnedData.data.message + ". Loading defaults.");
          }
        })
        .catch((error) => {
          // No need to reset center and zoom values, will use defaults
          changeBranchData(defaultBranchData);
          console.error(error);
        });
    }
    if (defaultBranchData.length) {
      loadResellers();
    }

    return () => {
      changeBranchData();
    }

  }, [defaultBranchData, changeBranchData]);

  return {
    mapCenter,
    changeMapCenter,
    mapZoomLevel,
    changeMapZoomLevel,
    branchData,
  };
};

export default useResellersApi;
