import React from "react";
import Product from "./components/Product/Product";
import productImage_0 from "./assets/products/product_0.webp";
import productImage_1 from "./assets/products/product_1.webp";
import productImage_2 from "./assets/products/product_2.webp";
import productImage_default from "./assets/products/product_default.webp";
import productsAction from "./assets/products-action.webp";
import "./Products.css";


export const Products = (props) => {

  const productImages = [
    productImage_0,
    productImage_1,
    productImage_2
  ];

  return (
    <div id="products-services" className="text-center">
      <div className="container">
        <div className="row ps-country">
          <h2 className="ps-title">
            <span>Alientech</span>
            <span>Argentina</span>
          </h2>
          <p className="ps-title-desc">{props.data ? props.data.titleDesc : "Loading"}</p>
        </div>
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>{props.data ? props.data.title : "Loading"}</h2>
          <h3>{props.data ? props.data.subTitle : "Loading"}</h3>
          <p>{props.data ? props.data.mainDesc : "Loading"}</p>
        </div>
        <div className="row ps-items-container">
          {props.data
            ? props.data.products.map((d, i) => (
                <div key={i + "-" + d.title}>
                  <Product
                    title = {d.title}
                    image = {(productImages[i] ? productImages[i] : productImage_default)}
                    text = {d.text}
                    url = {d.url}
                  />
                </div>
              ))
            : <></>}
        </div>
      </div>
      <div className="ps-equipment-container">
        <span className="ps-equipment-text">
          <span className="ps-equipment-text-1">
            <span>Lea&nbsp;-&nbsp;</span><span>Modifique</span><span>&nbsp;-&nbsp;Escriba</span>
          </span>
          <br />
          <span className="ps-equipment-text-2">
            <span>Pruebe&nbsp;-&nbsp;Repita</span>
          </span>
        </span>
        <img
          className="ps-equipment-image"
          alt="Alientech Equipment"
          src={productsAction}
        />
      </div>
    </div>
  );
};
