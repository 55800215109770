import React from "react";
import { Navigation } from "../../components/Navigation/Navigation";
import { Header } from "./components/Header/Header";
import { Products } from "./components/Products/Products";
import { Academy } from "./components/Academy/Academy";
import { Resellers } from "./components/Resellers/Resellers";
import { Contact } from "./components/Contact/Contact";
import { Footer } from "../../components/Footer/Footer";
import SmoothScroll from "smooth-scroll";
import "./Home.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

export const Home = (props) => {

  return (
    <div id="alientech-ar-home">
        <Navigation data={props.data.Navigation}/>
        <Header data={props.data.Header} />
        <Products data={props.data.Products} />
        <Academy data={props.data.Academy} />
        <Resellers data={props.data.Resellers} />
        <Contact data={props.data.Contact} />
        <Footer data={props.data.Footer} />
    </div>
  );
};

export default Home;
